// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-entreprenadjuridik-ata-arbeten-js": () => import("./../../../src/pages/entreprenadjuridik/ata-arbeten.js" /* webpackChunkName: "component---src-pages-entreprenadjuridik-ata-arbeten-js" */),
  "component---src-pages-entreprenadjuridik-fel-i-entreprenader-js": () => import("./../../../src/pages/entreprenadjuridik/fel-i-entreprenader.js" /* webpackChunkName: "component---src-pages-entreprenadjuridik-fel-i-entreprenader-js" */),
  "component---src-pages-entreprenadjuridik-forseningsvite-js": () => import("./../../../src/pages/entreprenadjuridik/forseningsvite.js" /* webpackChunkName: "component---src-pages-entreprenadjuridik-forseningsvite-js" */),
  "component---src-pages-entreprenadjuridik-index-js": () => import("./../../../src/pages/entreprenadjuridik/index.js" /* webpackChunkName: "component---src-pages-entreprenadjuridik-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-offentlig-upphandling-avtalstvister-js": () => import("./../../../src/pages/offentlig-upphandling/avtalstvister.js" /* webpackChunkName: "component---src-pages-offentlig-upphandling-avtalstvister-js" */),
  "component---src-pages-offentlig-upphandling-bristande-uppfyllelse-av-skall-krav-js": () => import("./../../../src/pages/offentlig-upphandling/bristande-uppfyllelse-av-skall-krav.js" /* webpackChunkName: "component---src-pages-offentlig-upphandling-bristande-uppfyllelse-av-skall-krav-js" */),
  "component---src-pages-offentlig-upphandling-index-js": () => import("./../../../src/pages/offentlig-upphandling/index.js" /* webpackChunkName: "component---src-pages-offentlig-upphandling-index-js" */),
  "component---src-pages-offentlig-upphandling-oklara-kvalificeringskrav-js": () => import("./../../../src/pages/offentlig-upphandling/oklara-kvalificeringskrav.js" /* webpackChunkName: "component---src-pages-offentlig-upphandling-oklara-kvalificeringskrav-js" */),
  "component---src-pages-offentlig-upphandling-oklara-utvarderingskriterier-js": () => import("./../../../src/pages/offentlig-upphandling/oklara-utvarderingskriterier.js" /* webpackChunkName: "component---src-pages-offentlig-upphandling-oklara-utvarderingskriterier-js" */),
  "component---src-pages-offentlig-upphandling-overprovning-overklagande-av-tilldelningsbeslut-js": () => import("./../../../src/pages/offentlig-upphandling/overprovning-overklagande-av-tilldelningsbeslut.js" /* webpackChunkName: "component---src-pages-offentlig-upphandling-overprovning-overklagande-av-tilldelningsbeslut-js" */),
  "component---src-pages-offentlig-upphandling-skadestand-js": () => import("./../../../src/pages/offentlig-upphandling/skadestand.js" /* webpackChunkName: "component---src-pages-offentlig-upphandling-skadestand-js" */),
  "component---src-pages-privacy-notice-js": () => import("./../../../src/pages/privacy-notice.js" /* webpackChunkName: "component---src-pages-privacy-notice-js" */),
  "component---src-pages-skickat-js": () => import("./../../../src/pages/skickat.js" /* webpackChunkName: "component---src-pages-skickat-js" */)
}

